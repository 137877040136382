<template>
  <div class="bg-white">
    <div class="flex items-center justify-between px-4 py-4 mt-2 bg-white">
      <TitlePlus title="Rider Passes" :hide-plus="true" />
      <DateRangePicker
        @apply-date="onApplyFilterDateRange"
        @calcel-date="onApplyFilterDateRange"
      />
    </div>

    <div class="flex flex-col px-4 mt-4 mb-10 md:items-center md:flex-row">
      <card-tax-stat
        title="Total Passes"
        :value="getTotalPass"
        variant="green"
        class="mr-3"
      />
      <card-tax-stat
        title="Current Balance"
        :value="getPassBalance"
        variant="blue"
        class="mr-3"
      />
    </div>

    <FSTable
      :fst-id="`riderPass`"
      :endpoint="
        `/dashboard/riders/${$route.params.id}/pass-subscription-records/`
      "
      :headers="tableHeaders"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="itemIndex"
          text-fallback-always
        >
          <FSTableRowItem
            :text="item.pass_id"
            :truncate="-5"
            :to="{ name: 'PassDetails', params: { id: item.pass_id } }"
          />

          <FSTableRowItem
            :text="getFormattedDate(item.pass_start_date, 'D MMM, YYYY')"
          />

          <FSTableRowItem
            :text="getFormattedDate(item.pass_end_date, 'D MMM, YYYY')"
          />

          <FSTableRowItem :text="item.pass_type" />

          <FSTableRowItem :text="`${symbol} ${item.spent_amount_from_pass}`" />

          <FSTableRowItem :text="`${symbol} ${item.pass_rate}`" />

          <FSTableRowItem>
            <x-status
              :text="item.status"
              :variant="getItemVariant(item.status)"
            />
          </FSTableRowItem>
          <!-- <FSTableRowItem>
            <oto-eye-icon v-tooltip.bottom="'View & Download Invoice'" />
          </FSTableRowItem>

          <FSTableRowItem>
            <div
              class="refund-action"
              :class="item.is_refunded ? 'refunded' : 'refund'"
              @click="showRefundPopup(item)"
            >
              {{ item.is_refunded ? 'Refunded' : 'Refund' }}
            </div>
          </FSTableRowItem> -->
        </FSTableRow>
      </template>
    </FSTable>
  </div>
</template>

<script>
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
// import OtoEyeIcon from '@/components/ui/OtoEyeIcon'
// import RefundPopup from '@/views/transactions/RefundPopup.vue'
import CardTaxStat from '@/components/cards/TaxStat'
import { getFormattedDate } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewRiderUserPass',
  components: {
    DateRangePicker,
    // OtoEyeIcon,
    // RefundPopup,
    CardTaxStat,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    TitlePlus: () => import('@/components/ui/TitlePlus'),
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total: '0',
          balance: '0.00',
          current_pass: null,
        },
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        { text: 'Pass ID', width: '10%', sort: 'pass_id' },
        { text: 'Start Date', width: '20%', sort: null },
        { text: 'End Date', width: '20%', sort: null },
        { text: 'Pass Type', width: '10%', sort: null },
        { text: 'Spent Amount', width: '15%', sort: null },
        { text: 'Pass Deposit', width: '15%', sort: null },
        { text: 'Status', width: '10%', sort: null },
      ],
    }
  },
  computed: {
    getTotalPass() {
      return `${this.indexMetaData?.summary?.total}`
    },
    getPassBalance() {
      return `${this.symbol} ${this.indexMetaData?.summary?.balance}`
    },
  },
  mounted() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDate,
    getItemVariant(status) {
      console.log('item-status', status)
      if (status === 'Active') return 'green'
      if (status === 'Inactive') return 'gray'
      return 'red'
    },
  },
}
</script>
